import Link from 'gatsby-link';
import * as React from 'react';

import Shell from '../../components/Shell';

export default () => {
    return (
        <Shell>
            <div className="gallery">
                <h1>Gallery</h1>

                <div className="exhibition-row">
                    <div className="exhibition">
                        <Link to="/gallery/pets">
                            <div className="cover" />
                        </Link>
                    </div>
                    <div className="exhibition">
                        <Link to="/gallery/science">
                            <div className="cover" />
                        </Link>
                    </div>
                    <div className="exhibition">
                        <Link to="/gallery/bugs">
                            <div className="cover" />
                        </Link>
                    </div>
                </div>
            </div>
        </Shell>
    );
};
